import moment from 'moment'
import pickBy from 'lodash/pickBy'
import i18n from 'services/i18n'

import { isValidValue } from 'helpers/values'
import { parseCurrency, formatCurrencyWithCountryCode } from 'helpers/currency'
import { humanizeLongNumberWithLocale } from 'helpers/formatters'
import { getSeasonalityPeakMonthDisplay } from './opportunity_finder'
import { formatMomentDate } from './date'

export const getSeasonalityDisplay = scoreValue => {
  if (scoreValue == 1) return i18n.t('common:keyword.verylow', 'Very Low')

  if (scoreValue == 2) {
    return i18n.t('common:keyword.low', 'Low')
  }
  if (scoreValue == 3) {
    return i18n.t('common:keyword.medium', 'Medium')
  }
  if (scoreValue == 4) {
    return i18n.t('common:keyword.high', 'High')
  }
  if (scoreValue == 5) {
    return i18n.t('common:keyword.veryhigh', 'Very High')
  }
}

export const getCompetitionDisplay = value => {
  const scoreValue = Math.round(value)
  if ([0, 1, 2].includes(scoreValue)) {
    return i18n.t('common:keyword.verylow', 'Very Low')
  }
  if ([3, 4].includes(scoreValue)) {
    return i18n.t('common:keyword.low', 'Low')
  }
  if ([5, 6].includes(scoreValue)) {
    return i18n.t('common:keyword.medium', 'Medium')
  }
  if ([7, 8].includes(scoreValue)) {
    return i18n.t('common:keyword.high', 'High')
  }
  if ([9, 10].includes(scoreValue)) {
    return i18n.t('common:keyword.veryhigh', 'Very High')
  }
}

export const getDisplayScore = (attribute, value) => {
  if (attribute === 'competition') {
    return getCompetitionDisplay(value / 10)
  }
  if (attribute === 'classification5') {
    return getSeasonalityDisplay(value)
  }
  if (attribute === 'peakWeek') {
    return moment(value, 'MMM DD').format('MMMM')
  }
  if (['avgUnitsSold', 'estimatedExactSearchVolume'].includes(attribute)) {
    return Math.round(value).toLocaleString()
  }
  return Math.round(value / 10).toLocaleString()
}

// The backend should search using a minimum of 151 but the frontend should show 151+
export const getSearchVolumeDisplay = (value = 0, displayMax) => {
  if (value <= 151) {
    return `150+`
  }
  if (value >= displayMax) {
    return `${displayMax.toLocaleString()}+`
  }

  return value.toLocaleString()
}

export const getCurrencyDisplay = (value, currencyCode, displayMax) => {
  if (value < displayMax) return parseCurrency(value, currencyCode)
  return `${parseCurrency(value, currencyCode)}+`
}

export const translateEaseOfRank = value => {
  switch (true) {
    case value >= 0 && value <= 30:
      return i18n.t('common:keyword.veryDifficult', 'Very Difficult')
    case value >= 31 && value <= 50:
      return i18n.t('common:keyword.difficult', 'Difficult')
    case value >= 51 && value <= 69:
      return i18n.t('common:keyword.somewhatDifficult', 'Somewhat Difficult')
    case value >= 70 && value <= 89:
      return i18n.t('common:keyword.moderate', 'Moderate')
    case value >= 90 && value <= 100:
      return i18n.t('common:keyword.Easy', 'Easy')
    default:
      return ''
  }
}

export const translateInListing = value => {
  switch (value) {
    case -1:
      return '-'
    case 0:
      return i18n.t(
        'rank_tracker:rankTrackerV2.KeywordDetails.ProductInfo.notInListing',
        'Not in Listing'
      )
    case 1:
      return i18n.t(
        'rank_tracker:rankTrackerV2.KeywordDetails.ProductInfo.partialInListing',
        'Partial'
      )
    default:
      return i18n.t(
        'rank_tracker:rankTrackerV2.KeywordDetails.ProductInfo.inListing',
        'In Listing'
      )
  }
}

export const translateMonth = month => {
  switch (month) {
    case 'January':
      return i18n.t('common:keyword.month.January', 'January')
    case 'February':
      return i18n.t('common:keyword.month.February', 'February')
    case 'March':
      return i18n.t('common:keyword.month.March', 'March')
    case 'April':
      return i18n.t('common:keyword.month.April', 'April')
    case 'May':
      return i18n.t('common:keyword.month.May', 'May')
    case 'June':
      return i18n.t('common:keyword.month.June', 'June')
    case 'July':
      return i18n.t('common:keyword.month.July', 'July')
    case 'August':
      return i18n.t('common:keyword.month.August', 'August')
    case 'September':
      return i18n.t('common:keyword.month.September', 'September')
    case 'October':
      return i18n.t('common:keyword.month.October', 'October')
    case 'November':
      return i18n.t('common:keyword.month.November', 'November')
    case 'December':
      return i18n.t('common:keyword.month.December', 'December')
    default:
      return month
  }
}

export const convertRangeFilterStringsToNumbers = filters => {
  const minMaxFiltersData = pickBy(filters, value => value.type === 'range')
  const minMaxFilters = Object.entries(minMaxFiltersData).map(
    ([key, value]) => [
      key,
      {
        ...value,
        min: parseInt(value.min, 10) || 0,
        max: parseInt(value.max, 10) || undefined
      }
    ]
  )
  const updatedMinMaxFilters = Object.fromEntries(minMaxFilters)
  return { ...filters, ...updatedMinMaxFilters }
}

/**
 * Formats any keyword attribute
 * @param {*} attribute - The key of the attribute to format
 * @param {*} keyword - The keyword object
 * @returns A formatted attribute or undefined if the value is invalid
 */
export const formatKeywordAttribute = (attribute, keyword) => {
  if (!keyword) return undefined

  const value = keyword[attribute]

  if (!isValidValue(value)) return undefined

  if (typeof value === 'string' && (value === 'N/A' || value.length === 0))
    return undefined

  if (typeof value === 'number' && value <= 0) return undefined

  const { country } = keyword

  switch (attribute) {
    case 'avgPrice':
      return formatCurrencyWithCountryCode(value, country)
    case 'avgUnitsSold':
    case 'estimatedExactSearchVolume':
      return humanizeLongNumberWithLocale(value)
    case 'opportunityScore': {
      const number = Number.isNaN(value) ? 0 : parseInt(value, 10)
      return number > 0 ? Math.round(number / 10) : undefined
    }
    case 'updatedAt': {
      return formatMomentDate(value, 'MMM DD, YYYY') || '-'
    }

    case 'monthlyTrend':
    case 'quarterlyTrend':
      // Show as an integer followed by '%'
      return `${parseInt(value, 10)}%`

    case 'competition':
      // The snippet divides value by 10 first to interpret the competition score.
      return getCompetitionDisplay(value / 10)

    case 'classification5':
      // Ex.: "Seasonality" column
      return getSeasonalityDisplay(value)

    case 'peakWeek':
      return (
        getSeasonalityPeakMonthDisplay(keyword.classification5, value) ||
        'No Peak Month'
      )

    default:
      return keyword[attribute]
  }
}
